
import notifications from '@iso/components/Feedback/Notification';
const { checkNetworkStatus } = require('check-network-status');

const Network = {
    /**
     * Valida cuanto tiempo de vida le queda al access token y lo refresca
     * @returns
     */
    check: async () => {
        checkNetworkStatus({
            timeout: 3000
        }).then(value => {
            if (!value) {
                console.log('Sin acceso a internet');
                notifications['warning']({
                    message: 'Sin Acceso a Internet',
                    description: 'Tu red no esta conectada a Internet',
                    duration: 0
                });
            } else {
                //console.log('tienes internet');
            }
        });
        return;
    },
}

export default Network;